exports.components = {
  "component---src-components-markdown-tsx": () => import("./../../../src/components/markdown.tsx" /* webpackChunkName: "component---src-components-markdown-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-ja-tsx": () => import("./../../../src/pages/index.ja.tsx" /* webpackChunkName: "component---src-pages-index-ja-tsx" */),
  "component---src-pages-index-ko-tsx": () => import("./../../../src/pages/index.ko.tsx" /* webpackChunkName: "component---src-pages-index-ko-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-index-tw-tsx": () => import("./../../../src/pages/index.tw.tsx" /* webpackChunkName: "component---src-pages-index-tw-tsx" */)
}

